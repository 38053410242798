import { IProduct } from 'typings/generated/contentful';

import { LOCALE } from './locale';

export const updateProductScore = (product: IProduct) => {
  fetch(`${process.env.GATSBY_PRODUCT_SCORE_UPDATE_URL}/${product.sys.id}`, {
    headers: {
      locale: LOCALE,
    },
  });
};
