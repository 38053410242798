import React, { ReactElement } from 'react';
import Loader from 'src/assets/img/loader.svg';

function LoadingIndicator(): ReactElement {
  return (
    <div className="flex justify-center pt-16 pb-8 text-x-orange">
      <Loader width="8rem" />
    </div>
  );
}

export default LoadingIndicator;
