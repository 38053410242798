import React, { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import MerchantNotice from 'src/components/misc/merchant-notice';
import CardFeed from 'src/components/product/card.feed';
import LoadingIndicator from 'src/components/widget/loading-indicator';
import { PAGE_SIZE } from 'src/hooks/usePagination';
import { IProduct } from 'typings/generated/contentful';

type Props = {
  products: IProduct[];
  hasMore: boolean;
  loadMore: Function;
};

const FeedProductListDesktop: React.FC<Props> = ({
  products,
  hasMore,
  loadMore,
}) => {
  return (
    <InfiniteScroll
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<LoadingIndicator key="loading" />}
    >
      <div className="grid grid-cols-3 gap-8">
        {products.map((product, i) => (
          <Fragment key={product.sys.id}>
            <div className="flex justify-center">
              <CardFeed product={product} />
            </div>
            {i === PAGE_SIZE - 1 && <MerchantNotice />}
          </Fragment>
        ))}
      </div>
    </InfiniteScroll>
  );
};

export default FeedProductListDesktop;
