import React from 'react';
import ReactMarkdown from 'react-markdown';
import AnchorLink from 'src/components/widget/anchor-link';
import PriceDisplay from 'src/components/widget/price-display';
import useTranslation from 'src/hooks/useTranslation';
import { relativeSlug } from 'src/util/product-slug';
import { updateProductScore } from 'src/util/update-product-score';
import { IProduct } from 'typings/generated/contentful';

import ProductImage from '../widget/product-image';

const TITLE_FONT_SIZE = 21;
const DESCRIPTION_FONT_SIZE = 13;
const DESCRIPTION_LINE_HEIGHT = 1.4;

interface Props {
  product: IProduct;
}

const CardFeed = (props: Props) => {
  const { product } = props;
  const t = useTranslation();
  const href = relativeSlug(product.fields);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <div
        className="relative flex items-center justify-center h-12 text-center title"
        style={{ fontSize: TITLE_FONT_SIZE }}
      >
        <a aria-label={product.fields.title} href={href}>
          <div className="px-1 pb-0">{product.fields.title}</div>
        </a>
      </div>
      <a aria-label={product.fields.title} href={href}>
        <div className="relative w-full overflow-hidden">
          <ProductImage product={product} />
        </div>
      </a>
      <div className="flex flex-col flex-1">
        <div
          className="flex-1 w-full py-2 mt-2"
          style={{
            fontSize: DESCRIPTION_FONT_SIZE,
            lineHeight: DESCRIPTION_LINE_HEIGHT,
          }}
        >
          <ReactMarkdown
            className="text-justify markdown"
            linkTarget="_blank"
            source={product.fields.description}
          />
        </div>
        <div className="flex items-center justify-between w-full py-4">
          <PriceDisplay icon={false} price={product.fields.price} />
          <AnchorLink
            className="btn btn-yellow"
            href={product.fields.link}
            onClick={() => updateProductScore(product)}
          >
            {t('moreInfo')}
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default CardFeed;
