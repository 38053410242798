import { useEffect, useRef, useState } from 'react';
import { contentfulClient } from 'src/util/contentful-client';
import { Paginator } from 'src/util/paginator';
import { IProduct } from 'typings/generated/contentful';

export const PAGE_SIZE = 12;

type PaginationOptions = {
  query: any;
  paginator: Paginator;
};

const initialItems = {
  items: [],
  total: 0,
};

export const usePagination = ({ query, paginator }: PaginationOptions) => {
  const offset = useRef(0);
  const [items, setItems] = useState<{ items: IProduct[]; total: number }>(
    initialItems
  );
  const [fetching, setFetching] = useState(false);
  const queryHash = JSON.stringify(query);

  const loadNextPage = () => {
    console.log({ items });
    if (fetching) {
      return;
    }
    setFetching(true);
    const nextPage = paginator.getNextPage(offset.current);
    contentfulClient
      .getEntries({
        ...query,
        limit: PAGE_SIZE,
        skip: nextPage * PAGE_SIZE,
      })
      .then((result) => {
        setItems((curr) => ({
          items: [
            ...curr.items,
            ...paginator.mapPage(result.items as IProduct[]),
          ],
          total: curr.total,
        }));
        offset.current = nextPage;
      })
      .finally(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    setFetching(true);
    let firstPage;
    contentfulClient
      .getEntries({ ...query, limit: 0 })
      .then(({ total }) => {
        firstPage = paginator.getFirstPage(Math.ceil(total / PAGE_SIZE));
        return contentfulClient
          .getEntries({
            ...query,
            limit: PAGE_SIZE,
            skip: firstPage * PAGE_SIZE,
          })
          .then((result) => {
            setItems({
              items: paginator.mapPage(result.items as IProduct[]),
              total,
            });
            offset.current = firstPage;
          });
      })
      .finally(() => {
        setFetching(false);
      });
  }, [queryHash, paginator]);

  return {
    seeding: items.total === 0 && fetching,
    page: items.items,
    hasMore: items.items.length < items.total,
    total: items.total,
    loadNextPage,
  };
};
