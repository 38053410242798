const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
});

export const priceFormat = (price: number | undefined, prefix = false) => {
  if (!price && price !== 0) {
    return null;
  }
  const formatted = formatter.format(price);
  if (!prefix) {
    return formatted.slice(1, formatted.length);
  }
  return formatted;
};
