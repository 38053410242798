import React, { ReactElement } from 'react';
import IconPriceTag from 'src/assets/img/ic_price_tag_eur.svg';
import { priceFormat } from 'src/util/price-format';

interface Props {
  price: number | undefined;
  icon: boolean;
}

function PriceDisplay({ price, icon }: Props): ReactElement {
  const formattedPrice = priceFormat(price);
  if (formattedPrice === null) {
    return <div />;
  }

  return (
    <div className="flex items-center text-2xl">
      {!icon && ' €'}
      {icon && <IconPriceTag className="h-8 pr-2" />}
      {priceFormat(price)}
    </div>
  );
}

export default PriceDisplay;
