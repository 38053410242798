import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import { COLOR_GRAY } from 'src/constants';
import { getFluidImage } from 'src/util/get-fluid-image';
import { IProduct } from 'typings/generated/contentful';

type Props = {
  product: IProduct;
  mediaIndex?: number;
  width?: number;
  height?: number;
  className?: string;
};

const ProductImage = ({ product, className, mediaIndex }: Props) => {
  const index = mediaIndex || 0;
  return (
    <GatsbyImage
      backgroundColor={COLOR_GRAY}
      className={cx('w-full', className)}
      draggable={false}
      fluid={getFluidImage(product.fields.media?.[index])}
    />
  );
};

export default ProductImage;
