import React from 'react';
import InfoIcon from 'src/components/svg/info';
import { useStaticContext } from 'src/hooks/useStaticContext';
import { IMiscStaticContent } from 'typings/generated/contentful';

import ContentfulRichText from './contentful-rich-text';

const MerchantNotice = () => {
  const { miscStaticContent } = useStaticContext();
  const partnerNotification: IMiscStaticContent = miscStaticContent?.find(
    (content) => content.sys.id === 'vY0zRa1zrWL8wcgN1J3ze'
  );
  return (
    <div className="flex flex-col p-4 mt-4 space-y-2 text-sm text-white rounded md:col-span-3 md:mt-0 bg-x-orange">
      <div className="flex items-center space-x-2 font-bold">
        <InfoIcon />
        <span>{partnerNotification.fields.title}</span>
      </div>
      <ContentfulRichText document={partnerNotification.fields.description} />
    </div>
  );
};

export default MerchantNotice;
