import { t } from 'src/assets/translations';
import {
  ICustomGiftlistFields,
  IProductFields,
} from 'typings/generated/contentful';

import { slugify } from './slugify';

export const absoluteSlug = (
  product: IProductFields | ICustomGiftlistFields,
  isClient: boolean
) => {
  const host = isClient ? window.origin : process.env.GATSBY_HOST;
  return encodeURIComponent(
    `${host}/${slugify(t('products'))}/${product?.slug}`
  );
};

export const relativeSlug = (product: IProductFields) =>
  `/${slugify(t('products'))}/${product?.slug}`;
