import * as contentful from 'contentful';

const client = contentful.createClient({
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN || '',
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID || '',
  host: process.env.GATSBY_CONTENTFUL_HOST,
  removeUnresolved: true,
  resolveLinks: true,
});

const { getEntries } = client;

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const firebase = require('firebase');

  firebase.initializeApp({
    projectId: 'krass-geil-de',
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  });

  let firebasePage;

  client.getEntries = (query) => {
    if (query.order === '-virtual.outboundClicks' && query.limit > 0) {
      if (query.skip === 0) {
        firebasePage = null;
      }
      let orderPromise = firebase
        .firestore()
        .collection('scoreIndex')
        .orderBy('score', 'desc')
        .limit(query.limit);
      if (firebasePage) {
        orderPromise = orderPromise.startAfter(firebasePage);
      }
      return orderPromise.get().then((snap) => {
        firebasePage = snap.docs[snap.docs.length - 1];
        const order = snap.docs.map((doc) => doc.id);
        return getEntries({
          ...query,
          limit: undefined,
          skip: undefined,
          order: undefined,
          'sys.id[in]': order.join(','),
        });
      });
    }
    if (query.order === '-virtual.outboundClicks') {
      query.order = undefined;
    }
    return getEntries(query);
  };
}

export const contentfulClient = client;
